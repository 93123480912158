import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../../components/global/Layout"
import Seo from "../../components/global/Seo"
import ListingCard from "../../components/templates/ListingCard"

export default function ListingIndex({ data }) {
    const frontMatter = data.allSanityListing.nodes
    return (
        <>
            <Layout>
                <Seo
                    title="CPA, Bookkeeping, and Payroll Practices for Sale"
                    description="View CPA, Bookkeeping, and Payroll practices for sale in Orange County"
                />
                <div className="section bg">
                    <div className="container">
                        <div className="grid grid-cols-1">
                            {frontMatter.map((node) => {
                                return (
                                    <ListingCard
                                        link={"/listings/" + node._id}
                                        title={node.title}
                                        price={node.price}
                                        annualGross={node.annual_gross}
                                        description={node.shortDescription}
                                        area={node.area}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export const query = graphql`
{
    allSanityListing {
      nodes {
        price
        status
        title
        _id
        annual_gross
        shortDescription
        area
      }
    }
  }
  
  
`