import { Link } from "gatsby"
import * as React from "react"
import PrimaryButton from "../ui/PrimaryButton"

export default function ListingCard({ link, title, price, annualGross, area }) {
    return (
        <>
            <Link to={link} className="border my-3 hover:shadow-md hover:transition-all transition-all">
                <div className="w-full lg:flex bg-white">
                    <div className="p-6 items-center w-full">
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 items-center">
                            <div>
                                <h2 className="text-xl font-bold">{title}</h2>
                                {area && <p>{area}</p>}
                            </div>
                            {annualGross &&
                                <div>
                                    <h3 className=" text-sm mb-2">Annual Gross</h3>
                                    <span className="font-bold">{annualGross}</span>
                                </div>
                            }
                            {price &&
                                <div>
                                    <h3 className=" text-sm mb-2">Asking Price</h3>
                                    <span className="font-bold">{price}</span>
                                </div>
                            }
                            <div>
                            <PrimaryButton
                                    label="View Listing"
                                    link={link}
                                    className="primary-button"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}